import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const InfoPage = () => (
  <Layout pageInfo={{ pageName: "info" }}>
    <SEO title="Info" />
    <h3 className="text-center text-white mb-4">
      POSTPONED until October 8 - 11
                </h3>
    <h3 className="text-center mb-4">
      :: a multi-venue weekend of electronic music + dancing in Chapel Hill, NC ::
                </h3>

    <h3 className="text-center mb-4">
    presented by Nightlight Bar + Club, All Day Records, The ArtsCenter, Local 506, The Northside District
    </h3>
  </Layout>
)

export default InfoPage